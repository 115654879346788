



import { Component, Prop, Vue } from 'vue-property-decorator';
import LocalDataService from '@/service/LocalDataServices';
import InvitationContainer from '@/container/auth/InvitationContainer.vue';
@Component({
  components: {
    InvitationContainer,
  },
})
export default class InvitationScene extends Vue {
}
