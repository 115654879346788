












































































import { Vue } from 'vue-property-decorator';
import { commonModule } from '@/store/viewModule/common/commonModule.ts';
import { signUpFromUrl } from '@/api/client';

interface DataType {
  teamId: string;
  email: string;
  isValid: boolean;
}

export default Vue.extend({
  name: 'InvitationContainer',

  data: (): DataType => {
    return {
      teamId: '',
      email: '',
      isValid: false,
    };
  },

  computed: {
    rules() {
      return {
        required: (value) => !!value || this.$t('validator.required'),
        email: (email) => {
          const emailRegExp = /^[A-Za-z0-9]{1}[A-Za-z0-9_._-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;
          return emailRegExp.test(email) || this.$t('validator.email');
        },
      };
    },
    showLoader() {
      return commonModule.showLoader;
    },
  },

  methods: {
    async register() {
      commonModule.setLoader(true);
      const inviteResponse = await signUpFromUrl({
        teamId: this.teamId,
        email: this.email,
        language: this.$i18n.locale,
      }).then((data) => {
        commonModule.setLoader(false);
        const response = data;
        if (response && response.sub) {
          alert(this.$t('sentInvitation'));
        } else if (data.error) {
          if (data.error === 'E041') {
            alert(this.$t('errorInviteAlert041'));
          } else {
            alert(this.$t('errorAuthAlert'));
          }
        } else {
          alert(this.$tc('errorUnexpected'));
        }
      }).catch(() => {
        commonModule.setLoader(false);
        alert(this.$t('errorUnexpected'));
      });
    },
  },

  async mounted() {
    const teamId = this.$route.query.teamId as string;
    if (teamId) {
      this.teamId = teamId || '';
    }
  },
});
